<template>
  <div class="component">
    <div class="row">
      <b-form-group
        label="Map Style"
        label-for="map-style"
        class="col-2"
      >
        <v-select
          v-model="mapInfo.style"
          label="label"
          :options="mapStyleOptions"
          :reduce="opt => opt.value"
          :clearable="false"
          :searchable="false"
          @input="updateData"
        />
      </b-form-group>
    </div>
    <google-map
      ref="mapRef"
      :center="{ lat: getLat, lng: getLng }"
      :zoom="getZoom"
      style="width: 100%; height: 400px"
    />

    <div class="row mt-2">
      <b-form-group
        label="Lat"
        label-for="lat"
        class="col-3"
      >
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            id="lat"
            v-model="mapInfo.lat"
            class="form-control-merge"
            name="lat"
            readonly
          />
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Lng"
        label-for="lng"
        class="col-3"
      >
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            id="lng"
            v-model="mapInfo.lng"
            class="form-control-merge"
            name="lng"
            readonly
          />
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Zoom"
        label-for="zoom"
        class="col-3"
      >
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            id="zoom"
            v-model="mapInfo.zoom"
            class="form-control-merge"
            name="zoom"
            readonly
          />
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="url"
        label-for="url"
        class="col-3"
      >
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            id="url"
            v-model="mapInfo.url"
            class="form-control-merge"
            name="lat"
            @input="updateData"
          />
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    vSelect,
  },
  props: {
    mapInfo: Object,
  },
  data() {
    return {
      mapStyleOptions: [{
        value: 'light',
        label: 'Light',
      }, {
        value: 'dark',
        label: 'Dark',
      }, {
        value: 'color',
        label: 'Color',
      }],
    }
  },
  computed: {
    getLat() {
      return this.mapInfo.lat ? parseFloat(this.mapInfo.lat) : 49.27211085878287
    },
    getLng() {
      return this.mapInfo.lng ? parseFloat(this.mapInfo.lng) : -123.06833267211914
    },
    getZoom() {
      return this.mapInfo.zoom ? parseFloat(this.mapInfo.zoom) : 14
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.mapRef.$mapPromise.then(map => {
        window.google.maps.event.addListener(
          map,
          'dragend',
          () => {
            this.mapInfo.lat = map.getBounds().getCenter().lat()
            this.mapInfo.lng = map.getBounds().getCenter().lng()
            this.mapInfo.zoom = map.getZoom()
            this.updateData()
          },
        )

        window.google.maps.event.addListener(
          map,
          'zoom_changed',
          () => {
            if (map.getBounds() && map.getZoom()) {
              this.mapInfo.lat = map.getBounds().getCenter().lat()
              this.mapInfo.lng = map.getBounds().getCenter().lng()
              this.mapInfo.zoom = map.getZoom()
            }
            this.updateData()
          },
        )
      })
    })
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.mapInfo)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
