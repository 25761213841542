<template>
  <div class="component">
    <b-form-group
      label="Color"
      label-for="color"
      class="col-3 p-0"
    >
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="color"
          v-model="advancedInfo.color"
          class="form-control-merge"
          name="color"
          @input="updateData"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group
      label="Popup Limitation"
      label-for="popup-limit"
      class="col-3 p-0"
    >
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="popup-limit"
          v-model="advancedInfo.popupLimitation"
          class="form-control-merge"
          name="popup-limit"
          @input="updateData"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group
      label="Search Page Landing URL"
      label-for="search-page"
      class="col-3 p-0"
    >
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="search-page"
          v-model="advancedInfo.searchLanding"
          class="form-control-merge"
          name="search-page"
          @input="updateData"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-if="isBrokerage"
      label="Agents URL"
      label-for="agents-page"
      class="col-3 p-0"
    >
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="agents-page"
          v-model="advancedInfo.agentsUrl"
          class="form-control-merge"
          name="agents-page"
          @input="updateData"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Items Sort by"
      label-for="items-sort-by"
      class="col-3 p-0"
    >
      <v-select
        v-model="advancedInfo.items_sort_by"
        label="label"
        :options="sortOpt"
        :reduce="opt => opt.value"
        :clearable="false"
        :searchable="false"
        @input="updateData"
      />
    </b-form-group>

    <b-form-group
      label="Whitelist IP Addresses (use , to seperate ips)"
      label-for="whitelist"
      class="col-3 p-0"
    >
      <b-form-textarea
        id="whitelist"
        v-model="advancedInfo.ipWhitelists"
        rows="15"
        @input="updateData"
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
  },
  props: {
    advancedInfo: Object,
  },
  computed: {
    isBrokerage() {
      if (this.$store.state.clientInfo.memberType === 'brokerage') {
        return true
      }
      return false
    },
  },
  data() {
    return {
      sortOpt: [
        {
          value: 'DateDesc',
          label: 'Date (New - Old)',
        },
        {
          value: 'DateAsc',
          label: 'Date (Old - New)',
        },
        {
          value: 'PriceDesc',
          label: 'Price (High - Low)',
        },
        {
          value: 'PriceAsc',
          label: 'Price (Low - High)',
        },
      ],
    }
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.advancedInfo)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
