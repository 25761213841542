<template>
  <div class="page">
    <b-card class="propertyContainer">
      <b-tabs content-class="mt-3">
        <b-tab
          title="Map"
          active
        >
          <Map
            :map-info="mapInfo"
            @update-data="updateData"
          />
        </b-tab>
        <b-tab title="Advanced">
          <Advanced
            :advanced-info="advancedInfo"
            @update-data="updateAdvancedData"
          />
        </b-tab>
      </b-tabs>
      <b-button
        variant="outline-primary"
        @click="submit"
      >
        Update
      </b-button>

    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Map from './components/Map.vue'
import Advanced from './components/Advanced.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    Map,
    Advanced,
  },
  data() {
    return {
      mapInfo: {
        lat: '',
        lng: '',
        zoom: '',
        url: '',
        style: '',
      },
      advancedInfo: {
        color: '',
        popupLimitation: '',
        searchLanding: '',
        agentsUrl: '',
        items_sort_by: '',
        ipWhitelists: '',
      },
      res: {},
    }
  },
  created() {
    this.$store.dispatch('website/getSettings').then(response => {
      if (response.code === 200) {
        const { data } = response
        this.res = data
        // MAP
        this.mapInfo.lat = data.map_lat
        this.mapInfo.lng = data.map_lng
        this.mapInfo.zoom = data.map_zoom
        this.mapInfo.url = data.map_url
        this.mapInfo.style = data.map_style

        // Advanced

        this.advancedInfo.website = data.website_url
        this.advancedInfo.color = data.theme_color
        this.advancedInfo.popupLimitation = data.popup_limit
        this.advancedInfo.searchLanding = data.search_page_url
        this.advancedInfo.agentsUrl = data.agents_url
        this.advancedInfo.items_sort_by = data.items_sort_by
        this.advancedInfo.ipWhitelists = data.whitelist_ips
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    updateData(data) {
      this.mapInfo = data
    },
    updateAdvancedData(data) {
      this.advancedInfo = data
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    submit() {
      // MAP
      this.res.map_lat = this.mapInfo.lat.toString()
      this.res.map_lng = this.mapInfo.lng.toString()
      this.res.map_zoom = this.mapInfo.zoom.toString()
      this.res.map_url = this.mapInfo.url
      this.res.map_style = this.mapInfo.style

      // Advanced
      this.res.theme_color = this.advancedInfo.color
      this.res.website_url = this.advancedInfo.website
      this.res.popup_limit = this.advancedInfo.popupLimitation
      this.res.search_page_url = this.advancedInfo.searchLanding
      this.res.agents_url = this.advancedInfo.agentsUrl
      this.res.items_sort_by = this.advancedInfo.items_sort_by
      this.res.whitelist_ips = this.advancedInfo.ipWhitelists

      this.$store.dispatch('website/updateSettings', this.res).then(response => {
        if (response.code === 200) {
          this.showToast('Updated', 'CheckCircleIcon', 'Setting has been updated successfully', 'success', 'bottom-right')
        } else {
          this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style>
</style>
